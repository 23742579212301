import StoreName from '@/enums/store-name';
import type { AttributeStoreResponse, UserType } from '@/types';
import { defineStore } from 'pinia';
import { computed, ComputedRef, ref, type Ref } from 'vue';
import Role from '@/enums/role';

const mainStore = defineStore(
    StoreName.MAIN,
    () => {
        const attributeStoreData: Ref<AttributeStoreResponse | null> = ref(null);
        const accessToken: Ref<string | null> = ref(null);
        const username: Ref<string | null> = ref(null);
        const userId: Ref<string> = ref('GPP_Frontend');
        const userType: Ref<UserType | null> = ref(null);

        const isPartner: ComputedRef<boolean> = computed((): boolean => userType.value === 'partner');
        const isCustomer: ComputedRef<boolean> = computed((): boolean => !!attributeStoreData.value?.role.includes(Role.CUSTOMER_FRONTEND));
        const isAdmin: ComputedRef<boolean> = computed((): boolean => !!attributeStoreData.value?.role.includes(Role.GLS_ADMIN));

        return {
            attributeStoreData,
            accessToken,
            username,
            userId,
            userType,
            isPartner,
            isCustomer,
            isAdmin
        };
    },
    {
        persist: {
            paths: ['attributeStoreData']
        }
    }
);

export default mainStore;
